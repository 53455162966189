import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './components/aside/aside.component';
import { HeaderComponent } from './components/header/header.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { RouterModule } from '@angular/router';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TableComponent } from './components/table/table.component';
import { EroomProductsComponent } from './components/eroom-products/eroom-products.component';
import { JacidiSvgComponent } from './components/svg/svg.component';
import { JacidiAppsSelectorComponent } from './components/apps-selector/apps-selector.component';
import { SafeHtmlPipe } from './components/pipes/safe-html.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [
    AsideComponent,
    HeaderComponent,
    UserProfileComponent,
    HeaderMobileComponent,
    TableComponent,
    EroomProductsComponent,
    JacidiSvgComponent,
    JacidiAppsSelectorComponent,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    OverlayPanelModule,
  ],
  exports: [
    HeaderComponent,
    AsideComponent,
    HeaderMobileComponent,
    TableComponent,
    JacidiSvgComponent,
    JacidiAppsSelectorComponent,
    SafeHtmlPipe,
  ],
})
export class SharedModule {}
