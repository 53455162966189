<div class="toggle" (click)="op.toggle($event)">
  <svg width="30" height="30" viewBox="0 0 40 40" fill="#bdc1c7">
    <circle cx="8.5" cy="9.5" r="3.5" />
    <circle cx="8.5" cy="20.5" r="3.5" />
    <circle cx="8.5" cy="31.5" r="3.5" />
    <circle cx="20.5" cy="9.5" r="3.5" />
    <circle cx="20.5" cy="20.5" r="3.5" />
    <circle cx="20.5" cy="31.5" r="3.5" />
    <circle cx="32.5" cy="9.5" r="3.5" />
    <circle cx="32.5" cy="20.5" r="3.5" />
    <circle cx="32.5" cy="31.5" r="3.5" />
  </svg>
</div>
<p-overlayPanel #op [appendTo]="'body'">
  <div class="apps">
    <ng-container *ngFor="let app of apps; let i = index">
      <a [ngClass]="{disable: !app.href}" [href]="app.href" target="_blank" rel="noopener noreferrer">
        <ng-container *ngIf="app.key === 'cartas'">
          <jacidi-svg
            [content]="paths[app.key]"
            fill="#2870dd"
            viewBox="0 0 100 100"
            width="34">
          </jacidi-svg>
        </ng-container>
        <ng-container *ngIf="app.key !== 'cartas'">
          <jacidi-svg
            [content]="paths[app.key]"
            fill="#2870dd">
          </jacidi-svg>
        </ng-container>
        {{ app.name }}
      </a>
    </ng-container>
  </div>
</p-overlayPanel>
