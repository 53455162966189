import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JacidiAppLink, JacidiAppLinkKey } from '../models/app-link.model';
import { APPS_SVGS_PATHS } from '../constants/svgs.constants';

@Component({
  selector: 'jacidi-apps-selector',
  templateUrl: './apps-selector.component.html',
  styleUrls: ['./apps-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JacidiAppsSelectorComponent {
  @Input() apps!: JacidiAppLink[];

  paths: Record<JacidiAppLinkKey, string> = APPS_SVGS_PATHS;
}
