export interface JacidiAppLink {
  key: JacidiAppLinkKey;
  name: string;
  href: string;
}

export enum JacidiAppLinkKey {
  Cast = 'cast',
  Hotspot = 'hotspot',
  Butler = 'butler',
  Desk = 'desk',
  Crm = 'crm',
  Pms = 'pms',
  Assistant = 'assistant',
  Be = 'be',
  Bi = 'bi',
  Chat = 'chat',
  Dam = 'dam',
  Erestaurant = 'erestaurant',
  Erp = 'erp',
  Hub = 'hub',
  Signage = 'signage',
  Tv = 'tv',
  Web = 'web',
  Cartas = 'cartas',
}
