import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { GraphqlService } from 'src/app/shared/services/graphql.service';
import { AVAILABLE_APPS } from '../constants/eroom.consts';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  @Output() public accordionAction = new EventEmitter<boolean>();
  @Input() public showAsideMobile = false;

  user;

  sites = [];
  cores = [];
  selectedSite: string;
  selectedCore: string;
  selectedCoreName: string;

  loading = true;

  apps = AVAILABLE_APPS;

  userProfile = false;

  constructor(
    private sessionService: SessionService,
    private graphqlService: GraphqlService
  ) {}

  ngOnInit(): void {
    this.sessionService.getUser().subscribe((user) => {
      this.user = user;
      this.sites = user.sites;

      this.selectedSite = user.selectedSite;
      this.cores = user.cores;
      this.selectedCore = user.selectedCore;

      this.loading = false;
    });
  }

  actionUserProfile(value) {
    this.userProfile = value;
  }

  handleClick() {
    this.showAsideMobile = !this.showAsideMobile;
    this.accordionAction.emit(this.showAsideMobile);
  }

  onChangeSite(value) {
    this.selectedSite = value;
    if (this.selectedSite !== null && this.selectedSite !== '') {
      const PARAM_CORE = `{
          castCoreList(siteId:"${this.selectedSite}"){
            id,
            name
          }
      }`;

      this.graphqlService.post(PARAM_CORE).subscribe((res) => {
        this.cores = res.data.castCoreList;
        this.selectedCore =
          res.data.castCoreList.length > 0 ? res.data.castCoreList[0].id : null;
        this.selectedCoreName =
          res.data.castCoreList.length > 0
            ? res.data.castCoreList[0].name
            : null;

        this.sessionService.setSite(
          this.selectedSite,
          this.cores,
          this.selectedCore,
          this.selectedCoreName
        );
      });
    }
  }

  onChangeCore(value) {
    this.selectedCore = value;
    this.selectedCoreName = '';
    if (this.selectedCore !== null && this.selectedCore !== '') {
      const core = this.cores.filter((obj) => obj.id === value);
      if (core.length > 0) {
        this.selectedCoreName = core[0].name;
        this.sessionService.setCore(this.selectedCore, this.selectedCoreName);
      }
    }
  }
}
