import { JacidiAppLink, JacidiAppLinkKey } from "../models/app-link.model";

export const AVAILABLE_APPS: JacidiAppLink[] = [
  {
    key: JacidiAppLinkKey.Cast,
    name: 'Cast',
    href: '',
  },
  {
    key: JacidiAppLinkKey.Hotspot,
    name: 'Hotspot',
    href: 'https://hotspot.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Crm,
    name: 'CRM',
    href: 'https://crm.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Butler,
    name: 'Butler',
    href: 'https://desk.uat.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Pms,
    name: 'PMS',
    href: 'https://pms.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Cartas,
    name: 'Cartas',
    href: 'https://cartas.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Assistant,
    name: 'Assistant',
    href: 'https://www.eroomsuite.com/assistant/',
  },
  {
    key: JacidiAppLinkKey.Be,
    name: 'BE+',
    href: 'https://www.eroomsuite.com/be/',
  },
  {
    key: JacidiAppLinkKey.Bi,
    name: 'BI',
    href: 'https://bi.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Chat,
    name: 'Chat',
    href: 'https://chatgpt.qa.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Dam,
    name: 'DAM',
    href: 'https://www.eroomsuite.com/dam/',
  },
  {
    key: JacidiAppLinkKey.Erestaurant,
    name: 'eRestaurant',
    href: 'https://www.eroomsuite.com/erestaurant/',
  },
  {
    key: JacidiAppLinkKey.Erp,
    name: 'ERP',
    href: 'https://www.eroomsuite.com/erp/',
  },
  {
    key: JacidiAppLinkKey.Hub,
    name: 'HUB',
    href: 'https://www.eroomsuite.com/hub/',
  },
  {
    key: JacidiAppLinkKey.Signage,
    name: 'Signage',
    href: 'https://signage.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Tv,
    name: 'TV',
    href: 'https://cast.eroomsuite.com/admin/',
  },
  {
    key: JacidiAppLinkKey.Desk,
    name: 'Desk',
    href: 'https://desk.uat.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Web,
    name: 'WEB',
    href: 'https://www.eroomsuite.com',
  },
];
