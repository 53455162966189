import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jacidi-svg',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="viewBox"
      [attr.fill]="fill"
      [innerHTML]="content | safeHtml"></svg>
  `,
  styles: [`
    :host {
      display: inline-flex;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JacidiSvgComponent {
  @Input() content!: string;
  @Input() width: string = '60';
  @Input() height: string = '60';
  @Input() viewBox: string = '0 0 60 60';
  @Input() fill: string = 'black';
}
