import { Component, OnInit } from '@angular/core';
import { GraphqlService } from '../../services/graphql.service';
import { SessionService } from '../../services/session.service';
import { User } from '../../interfaces/user';
import { AVAILABLE_APPS } from '../constants/eroom.consts';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: User;

  sites = [];
  cores = [];
  selectedSite: string;
  selectedCore: string;
  selectedCoreName: string;

  loading = true;

  userProfile = false;
  apps = AVAILABLE_APPS;

  constructor(
    private sessionService: SessionService,
    private graphqlService: GraphqlService
  ) {}

  ngOnInit(): void {
    this.sessionService.getUser().subscribe((user) => {
      this.user = user;
      this.sites = user.sites;
      this.cores = user.cores;

      this.selectedSite = user.selectedSite;
      this.selectedCore = user.selectedCore;

      this.loading = false;
    });
  }

  actionUserProfile(value) {
    this.userProfile = value;
  }

  onChangeSite() {
    if (this.selectedSite !== null && this.selectedSite !== '') {
      const PARAM_CORE = `{
          castCoreList(siteId:"${this.selectedSite}"){
            id,
            name
          }
      }`;

      this.graphqlService.post(PARAM_CORE).subscribe((res) => {
        this.cores = res.data.castCoreList;
        this.selectedCore =
          res.data.castCoreList.length > 0 ? res.data.castCoreList[0].id : null;
        this.selectedCoreName =
          res.data.castCoreList.length > 0
            ? res.data.castCoreList[0].name
            : null;

        this.sessionService.setSite(
          this.selectedSite,
          this.cores,
          this.selectedCore,
          this.selectedCoreName
        );
      });
    }
  }

  onChangeCore() {
    this.selectedCoreName = '';
    if (this.selectedCore !== null && this.selectedCore !== '') {
      const core = this.cores.filter((obj) => obj.id === this.selectedCore);
      if (core.length > 0) {
        this.selectedCoreName = core[0].name;
        this.sessionService.setCore(this.selectedCore, this.selectedCoreName);
      }
    }
  }
}
