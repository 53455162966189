<!--begin::Header-->
<div id="kt_header" class="header header-fixed" data-header-scroll="on">
  <!--begin::Header Wrapper-->
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!--begin::Menu Wrapper-->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        id="kt_header_menu_wrapper"
      >
        <!--begin::Menu-->
        <div
          id="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
        >
          <img src="assets/images/eRoom-Cast-Logo.svg" alt="Logo Brand" />
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Menu Wrapper-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center py-3 py-lg-2">
        <ng-select
          class="mr-5"
          *ngIf="!loading"
          [items]="sites"
          bindLabel="name"
          [placeholder]="'Sites'"
          bindValue="id"
          [clearable]="false"
          [(ngModel)]="selectedSite"
          (change)="onChangeSite()"
        ></ng-select>
        <ng-select
          class="mr-5"
          *ngIf="!loading"
          [items]="cores"
          bindLabel="name"
          [placeholder]="'Cores'"
          bindValue="id"
          [clearable]="false"
          [(ngModel)]="selectedCore"
          (change)="onChangeCore()"
        ></ng-select>
        <jacidi-apps-selector [apps]="apps"></jacidi-apps-selector>
        <div
          *ngIf="!loading"
          class="btn p-0 symbol symbol-40 symbol-success"
          id="kt_quick_user_toggle"
          (click)="actionUserProfile(true)"
        >
          <div class="user-info">
            <span class="user-info-name"
              >{{ user.name }} {{ user.surname }}</span
            >
            <span class="user-info-rol">{{ user.roles[0] }}</span>
          </div>
          <img alt="User Photo" src="assets/images/default.jpg" />
        </div>
        <!--end::User Info-->
        <!--begin::User Profile-->
        <app-user-profile
          [user]="user"
          [show]="userProfile"
          (showEvent)="actionUserProfile($event)"
        ></app-user-profile>
        <!--end::User Profile-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header Wrapper-->
</div>
<!--end::Header-->
